import {Music} from "../models/Music";
import {NewGuestGroup} from "../models/NewGuestGroup";
import {Guest} from "../models/Guest";
import {Group} from "../models/Group";

const getMusic = (token?: string): Promise<Music[]> => {
    const options: RequestInit = {
        method: 'GET',
        headers: {'Authorization': `Basic ${token}`}
    };
    return fetch('https://ellendavewedding.herokuapp.com/music', options)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
        });
};

const sendInvite = (groupId: string, token?: string): Promise<boolean> => {
    const options: RequestInit = {
        method: 'POST',
        headers: {'Authorization': `Basic ${token}`}
    };
    return fetch(`https://ellendavewedding.herokuapp.com/invite?groupId=${groupId}`, options)
        .then(response => response.ok);
};

const addGuests = (body: NewGuestGroup[], token?: string): Promise<boolean> => {
    const options: RequestInit = {
        method: 'POST',
        headers: {'Authorization': `Basic ${token}`, 'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    };
    return fetch(`https://ellendavewedding.herokuapp.com/addGuests`, options)
        .then(response => response.ok);
};

const updateGuest = (body: Guest, token?: string): Promise<boolean> => {
    const options: RequestInit = {
        method: 'PUT',
        headers: {'Authorization': `Basic ${token}`, 'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    };
    return fetch('https://ellendavewedding.herokuapp.com/guest', options)
        .then(response => response.ok);
};

const updateGroup = (body: Group, token?: string): Promise<boolean> => {
    const options: RequestInit = {
        method: 'PUT',
        headers: {'Authorization': `Basic ${token}`, 'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    };
    return fetch('https://ellendavewedding.herokuapp.com/group', options)
        .then(response => response.ok);
};

const deleteGuest = (guestId: string, token?: string): Promise<boolean> => {
    const options: RequestInit = {
        method: 'DELETE',
        headers: {'Authorization': `Basic ${token}`}
    };
    return fetch(`https://ellendavewedding.herokuapp.com/guest/${guestId}`, options)
        .then(response => response.ok);
};

export const adminApiClient = {
    getMusic,
    sendInvite,
    addGuests,
    updateGuest,
    updateGroup,
    deleteGuest,
};