import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Button, Card, CardContent, DialogActions, Grid} from "@mui/material";
import {Group} from "../models/Group";

interface StatsDialogProps {
    open: boolean,
    groups?: Group[],
    closeHandler: () => void
}

export default function StatsDialog({open, groups, closeHandler}: StatsDialogProps) {

    const getAttendingCount = () => {
        return groups?.map(group => group.guests).flat(1).filter(guest => guest.attending).length;
    };

    const getNotAttendingCount = () => {
        return groups?.map(group => group.guests).flat(1).filter(guest => guest.attending === false).length;
    };

    const getNoResponseCount = () => {
        return groups?.map(group => group.guests).flat(1).filter(guest => guest.attending === null).length;
    };

    const getBeefCount = () => {
        return groups?.map(group => group.guests).flat(1).filter(guest => guest.food === 'beef').length;
    };

    const getDuckCount = () => {
        return groups?.map(group => group.guests).flat(1).filter(guest => guest.food === 'duck').length;
    };

    const getPastaCount = () => {
        return groups?.map(group => group.guests).flat(1).filter(guest => guest.food === 'pasta').length;
    };

    const getDietaryCount = () => {
        return groups?.map(group => group.guests).flat(1).filter(guest => guest.dietary && guest.dietary !== 'N/A').length;
    };

    const getColor = () => {
        return "hsl(" + 360 * Math.random() + ',' +
            (25 + 70 * Math.random()) + '%,' +
            (85 + 10 * Math.random()) + '%)'
    };

    if (!groups) {
        return null;
    }

    return (
        <Dialog open={open} onClose={closeHandler} maxWidth="md" scroll="body" fullWidth disableAutoFocus>
            <DialogTitle>Our Wedding In Numbers</DialogTitle>

            <DialogContent className='font-regular-label'>
                <Grid container spacing={2} justifyContent="center">
                    <Card sx={{
                        maxWidth: 'sm',
                        marginTop: '1rem',
                        marginLeft: '1rem',
                        paddingBottom: '0',
                        backgroundColor: getColor()
                    }}>
                        <CardContent sx={{maxWidth: 'sm', marginTop: '1rem', marginLeft: '1rem', paddingBottom: '0'}}>
                            <Grid container spacing={2} direction="column" alignItems="stretch">
                                <div>
                                    <span style={{color: 'gray'}}>Attending: </span>
                                    <span style={{marginTop: '1rem'}}>{getAttendingCount()}</span>
                                </div>
                                <div>
                                    <span style={{color: 'gray'}}>Not Attending: </span>
                                    <span style={{marginTop: '1rem'}}>{getNotAttendingCount()}</span>
                                </div>
                                <div>
                                    <span style={{color: 'gray'}}>No Response: </span>
                                    <span style={{marginTop: '1rem'}}>{getNoResponseCount()}</span>
                                </div>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card sx={{
                        maxWidth: 'sm',
                        marginTop: '1rem',
                        marginLeft: '1rem',
                        paddingBottom: '0',
                        backgroundColor: getColor()
                    }}>
                        <CardContent sx={{maxWidth: 'sm', marginTop: '1rem', marginLeft: '1rem', paddingBottom: '0'}}>
                            <Grid container spacing={2} direction="column" alignItems="stretch">
                                <div>
                                    <span style={{color: 'gray'}}>Beef: </span>
                                    <span style={{marginTop: '1rem'}}>{getBeefCount()}</span>
                                </div>
                                <div>
                                    <span style={{color: 'gray'}}>Duck: </span>
                                    <span style={{marginTop: '1rem'}}>{getDuckCount()}</span>
                                </div>
                                <div>
                                    <span style={{color: 'gray'}}>Pasta: </span>
                                    <span style={{marginTop: '1rem'}}>{getPastaCount()}</span>
                                </div>
                                <div>
                                    <span style={{color: 'gray'}}>Dietary: </span>
                                    <span style={{marginTop: '1rem'}}>{getDietaryCount()}</span>
                                </div>

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={closeHandler}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};