import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom'
import {Group} from "../models/Group";
import GuestsTable from "./GuestsTable";
import DeleteDialog from "./DeleteDialog";
import {Guest} from "../models/Guest";
import Button from "@mui/material/Button";
import AddGuestsDialog from "./AddGuestsDialog";
import {NewGuestGroup} from "../models/NewGuestGroup";
import EditGroupDialog from "./EditGroupDialog";
import EditGuestDialog from "./EditGuestDialog";
import {Numbers, PersonAdd, QueueMusic} from "@mui/icons-material";
import MusicDialog from "./MusicDialog";
import {Music} from "../models/Music";
import {adminApiClient} from "./AdminApiClient";
import StatsDialog from "./StatsDialog";

export default function Admin() {
    const navigate = useNavigate();
    const {token} = useParams();
    const [groups, setGroups] = useState<Group[]>();
    const [showAddGuestsDialog, setShowAddGuestsDialog] = useState(false);
    const [showStatsDialog, setShowStatsDialog] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<Group>();
    const [selectedGuest, setSelectedGuest] = useState<Guest>();
    const [toDeleteGuest, setToDeleteGuest] = useState<Guest>();
    const [music, setMusic] = useState<Music[]>();

    const showMusicRecs = () => {
        adminApiClient.getMusic(token)
            .then(data => setMusic(data))
            .catch(error => console.log(error));
    };

    const addGuestHandler = () => {
        setShowAddGuestsDialog(true);
    };

    const addGuestsHandler = (newGuestGroup: NewGuestGroup) => {
        adminApiClient.addGuests([newGuestGroup], token)
            .then(ok => ok && fetchGuests())
            .catch(error => console.log(error));
    };

    const sendInviteHandler = (group: Group) => {
        adminApiClient.sendInvite(group.id, token)
            .then(ok => ok && fetchGuests())
            .catch(error => console.log(error));
    };

    const editGuestHandler = (guest: Guest) => {
        setSelectedGuest(guest);
    };

    const updateGuestHandler = (guest: Guest) => {
        adminApiClient.updateGuest(guest, token)
            .then(ok => ok && fetchGuests())
            .catch(error => console.log(error));
    };

    const editGroupHandler = (group: Group) => {
        setSelectedGroup(group);
    };

    const updateGroupHandler = (group: Group) => {
        adminApiClient.updateGroup(group, token)
            .then(ok => ok && fetchGuests())
            .catch(error => console.log(error));
    };

    const deleteGuestMenuHandler = (guest: Guest) => {
        setToDeleteGuest(guest);
    };

    const deleteGuestConfirmedHandler = (guest: Guest) => {
        setToDeleteGuest(undefined);
        adminApiClient.deleteGuest(guest.id, token)
            .then(ok => ok && fetchGuests())
            .catch(error => console.log(error));
    };

    const fetchGuests = () => {
        const options: RequestInit = {
            method: 'GET',
            headers: {'Authorization': `Basic ${token}`}
        };
        fetch('https://ellendavewedding.herokuapp.com/guests', options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    navigate("/");
                }
            })
            .then(data => {
                setGroups(data);
            })
            .catch(() => {
                navigate("/");
            });
    };

    useEffect(() => {
        if (!token) {
            navigate("/");
            return
        }

        fetchGuests();
    }, [token]);

    if (!token) {
        return null;
    }

    return <div className="admin">
        <div className="admin-container">
            <div className="admin-header">
                <span className="font-large-cursive-label margin-bottom-2-rem">Guest List</span>
                <div className="header-buttons-container">
                    <Button variant="outlined" startIcon={<Numbers/>} onClick={() => setShowStatsDialog(true)} className="margin-right-2-rem">Stats</Button>
                    <Button variant="outlined" startIcon={<QueueMusic/>} onClick={showMusicRecs} className="margin-right-2-rem">Music Req’s</Button>
                    <Button variant="outlined" startIcon={<PersonAdd/>} onClick={addGuestHandler}>Add Guests</Button>
                </div>
            </div>

            {groups && <GuestsTable
                groups={groups}
                sendInviteHandler={sendInviteHandler}
                editGroupHandler={editGroupHandler}
                editGuestHandler={editGuestHandler}
                deleteGuestHandler={deleteGuestMenuHandler}
            />}
        </div>
        <StatsDialog
            open={showStatsDialog}
            groups={groups}
            closeHandler={() => setShowStatsDialog(false)}
        />
        <MusicDialog
            musicReqs={music}
            closeHandler={() => setMusic(undefined)}
        />
        <AddGuestsDialog
            open={showAddGuestsDialog}
            addGuestsHandler={addGuestsHandler}
            closeHandler={() => setShowAddGuestsDialog(false)}
        />
        <EditGuestDialog
            guest={selectedGuest}
            updateGuestHandler={updateGuestHandler}
            closeHandler={() => setSelectedGuest(undefined)}
        />
        <EditGroupDialog
            group={selectedGroup}
            updateGroupHandler={updateGroupHandler}
            closeHandler={() => setSelectedGroup(undefined)}
        />
        <DeleteDialog
            guest={toDeleteGuest}
            deleteHandler={deleteGuestConfirmedHandler}
            cancelHandler={() => setToDeleteGuest(undefined)}
        />
    </div>
}