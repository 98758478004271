import React, {FormEvent} from 'react';
import {Guest} from "../models/Guest";
import {FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";

type GuestInfoProps = {
    guest: Guest;
    updateGuest: (guest: Guest) => void;
}

export default function GuestInfo({guest, updateGuest}: GuestInfoProps) {
    const updateAttending = (event: FormEvent) => {
        const attending = (event.target as HTMLInputElement).value === "Yes";
        if (attending) {
            updateGuest({
                ...guest,
                attending
            });
        } else {
            updateGuest({
                ...guest,
                attending,
                food: undefined,
                dietary: undefined,
                music: undefined
            });
        }
    };

    const updateFood = (event: FormEvent) => {
        const food = (event.target as HTMLInputElement).value;
        updateGuest({
            ...guest,
            food
        });
    };

    const updateDietary = (event: FormEvent) => {
        const dietary = (event.target as HTMLInputElement).value;
        updateGuest({
            ...guest,
            dietary
        });
    };

    const updateMusic = (event: FormEvent) => {
        const music = (event.target as HTMLInputElement).value;
        updateGuest({
            ...guest,
            music
        });
    };

    return <div className="guest-info">
        <span className="guest-info-name font-huge-label margin-top-2-rem margin-bottom-1-rem">{guest.name}</span>

        <div className="guest-info-section">
            <span className="font-regular-medium-label guest-info-question margin-bottom-1-rem">
                Will you be attending?
            </span>
            <RadioGroup
                aria-labelledby="attending"
                name="attending-radio-group"
                className="font-regular-label margin-bottom-05-rem"
                onChange={updateAttending}>
                <FormControlLabel value="Yes" control={<Radio/>} label="Yes"/>
                <FormControlLabel value="No" control={<Radio/>} label="No"/>
            </RadioGroup>
        </div>

        {guest.attending && <div className="flex-column">
            <span className="font-regular-medium-label guest-info-question margin-vertical-1-rem">
                What would you like for dinner?
            </span>
            <RadioGroup
                aria-labelledby="food"
                name="food-radio-group"
                className="font-regular-label"
                onChange={updateFood}>
                <FormControlLabel value="beef" control={<Radio/>} label="Braised Beef Short Rib"/>
                <span className="margin-bottom-1-rem margin-left-2-rem">
                    Parsnip puree, brussels sprouts, kale, grain mustard jus
                </span>
                <FormControlLabel value="duck" control={<Radio/>} label="Duck Breast"/>
                <span className="margin-bottom-1-rem margin-left-2-rem">
                    Roasted parsnips, charred rapini, honey glaze, spiced jus
                </span>
                <FormControlLabel value="pasta" control={<Radio/>} label="Hand-made Orecchiette"/>
                <span className="margin-bottom-2-rem margin-left-2-rem">
                    Porcini cream, hazelnuts, mint (Vegetarian)
                </span>
            </RadioGroup>

            <span className="font-regular-medium-label guest-info-question margin-bottom-05-rem">
                Any food restrictions? (optional)
            </span>
            <TextField className="margin-bottom-05-rem" value={guest.dietary} onChange={updateDietary}/>
            <span className="font-regular-medium-label guest-info-question margin-bottom-05-rem margin-top-1-rem">
                Any music requests for the reception? (optional)
            </span>
            <TextField className="margin-bottom-1-rem" value={guest.music} onChange={updateMusic}/>
        </div>}
    </div>
}