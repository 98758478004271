import React, {useEffect, useState} from 'react';
import overflowMenu from "../images/OverflowMenu.svg";
import {Property} from "csstype";
import Join from "./Join";
import Faqs from "./Faqs";
import Schedule from "./Schedule";
import Details from "./Details";

export default function Landing() {

    const [headerBarVisibility, setHeaderBarVisibility] = useState<Property.Visibility>("hidden");
    const [showHeaderBarBackground, setShowHeaderBarBackground] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", scrollEventListener)
    }, []);

    const onAnchorClick = (anchorId: string) => {
        const anchor = document.getElementById(anchorId);
        anchor?.scrollIntoView({behavior: "smooth"});
        setHeaderBarVisibility("hidden")
    };

    const onMenuClick = () => {
        headerBarVisibility === "hidden" ? setHeaderBarVisibility("visible") : setHeaderBarVisibility("hidden");
    };

    const scrollEventListener = () => {
        window.scrollY > 270 ? setShowHeaderBarBackground(true) : setShowHeaderBarBackground(false);
    };

    return <div className="landing">

        <div className="landing-content">
            <div className="home">
                <a id="home-anchor" className="anchor" style={{top: '0'}} href="/#"> </a>
                <div className="home-spacer"/>
                <div className="font-huge-cursive-label">Ellen & Dave</div>
                <div className="home-label font-large-label">11.04.22</div>
            </div>

            <Details/>
            <Schedule/>
            <Faqs/>
            <Join/>
        </div>

        <div
            className={`header-menu ${showHeaderBarBackground ? 'background-opaque' : 'header-menu-background-invisible'}`}>
            <img src={overflowMenu} onClick={onMenuClick} alt="menu-button"/>
        </div>

        <div id="header-bar"
             className={`${showHeaderBarBackground ? 'background-opaque' : 'header-bar-background-invisible'}`}
             style={{visibility: headerBarVisibility}}>
            <span onClick={() => onAnchorClick("home-anchor")} className="font-regular-label">Home</span>
            <span onClick={() => onAnchorClick("details-anchor")} className="font-regular-label">Details</span>
            <span onClick={() => onAnchorClick("schedule-anchor")} className="font-regular-label">Schedule</span>
            <span onClick={() => onAnchorClick("faq-anchor")} className="font-regular-label">FAQs</span>
        </div>

        <span
            className={`e-and-d font-regular-cursive-label ${showHeaderBarBackground ? 'e-and-d-visible' : 'e-and-d-invisible'}`}>E & D</span>
    </div>
}
