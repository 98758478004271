import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Add, Backspace} from '@mui/icons-material';
import {Group} from '../models/Group';

interface EditGroupDialogProps {
    group?: Group,
    updateGroupHandler: (group: Group) => void,
    closeHandler: () => void
}

export default function EditGroupDialog({
                                            group,
                                            updateGroupHandler,
                                            closeHandler
                                        }: EditGroupDialogProps) {
    const [editGroup, updateEditGroup] = useState(group);
    const [errorState, setErrorState] = useState("none");

    useEffect(() => {
        if (group) {
            updateEditGroup(JSON.parse(JSON.stringify(group)));
        }
    }, [group]);

    if (!editGroup) {
        return null;
    }

    const addEmailRow = () => {
        updateEditGroup({
            ...editGroup,
            emails: [
                ...editGroup.emails,
                {
                    email: "",
                    groupId: editGroup.id
                }
            ]
        });
    };

    const removeEmailRow = (index: number) => {
        const newEditGroup = {...editGroup};
        newEditGroup.emails.splice(index, 1);
        updateEditGroup(newEditGroup);
    };

    const setEmail = (email: string, index: number) => {
        const newEditGroup = {...editGroup};
        const emailAtIndex = newEditGroup.emails.at(index);
        if (emailAtIndex) {
            emailAtIndex.email = email;
            updateEditGroup(newEditGroup);
        }
    };

    const updateGroupButtonClick = () => {
        if (!editGroup?.names) {
            setErrorState('need group name');
            return;
        }
        if (editGroup?.emails.length === 0) {
            setErrorState('need email');
            return;
        }
        if (editGroup?.emails.findIndex(email => email.email.length === 0) >= 0) {
            setErrorState('blank email');
            return;
        }

        setErrorState('none');
        updateGroupHandler(editGroup);
        cleanAndCloseDialog();
    };

    const cleanAndCloseDialog = () => {
        setErrorState('none');
        updateEditGroup(undefined);
        closeHandler();
    };

    return (
        <Dialog open={Boolean(editGroup)}
                onClose={cleanAndCloseDialog}
                maxWidth="sm"
                scroll="body"
                fullWidth
                disableAutoFocus>
            <DialogTitle>Edit Group {editGroup?.names}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus={true}
                    margin="dense"
                    label="Group Name"
                    fullWidth
                    variant="outlined"
                    onChange={event => editGroup && updateEditGroup({...editGroup, names: event.target.value})}
                    value={editGroup?.names}
                />
                {errorState === 'need group name' && <DialogContentText style={{color: 'red'}}>
                    * Group name is required
                </DialogContentText>}

                <DialogContentText style={{marginTop: '2rem'}}>Guest Emails: {editGroup?.emails.length}</DialogContentText>
                {editGroup?.emails.map((email, index) => (
                    <div className="flex-row flex-justify-space-between" key={index}>
                        <TextField margin="dense" variant="outlined" fullWidth
                                   onChange={event => setEmail(event.target.value, index)}
                                   value={email.email}/>
                        <Button style={{height: '2rem', marginLeft: '1rem', marginTop: '2rem'}}
                                variant="outlined"
                                startIcon={<Backspace/>}
                                onClick={() => removeEmailRow(index)}>
                            Remove
                        </Button>
                    </div>
                ))}

                {errorState === 'need email' && <DialogContentText style={{color: 'red'}}>
                    * At least one email is required
                </DialogContentText>}
                {errorState === 'blank email' && <DialogContentText style={{color: 'red'}}>
                    * Email cannot be blank
                </DialogContentText>}

                <Button style={{height: '2rem', marginTop: '3rem'}}
                        variant="outlined"
                        startIcon={<Add/>}
                        onClick={addEmailRow}>
                    Add Email
                </Button>

            </DialogContent>
            <DialogActions>
                <Button onClick={cleanAndCloseDialog}>Cancel</Button>
                <Button onClick={updateGroupButtonClick}>Update Group</Button>
            </DialogActions>
        </Dialog>
    );
}
