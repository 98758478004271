import React from 'react';

export default function Join() {

    return <div className="join">
        <div className="photo-credit font-tiny-label uppercase">Photos by:
            <a href="http://www.maxwongphoto.com" target="_blank" rel="noreferrer">Max Wong Photo</a>
        </div>
        <div className="join-spacer"/>
        <span className="font-large-label font-white">We hope you can make it!</span>
        <span className="font-large-cursive-label font-white margin-bottom-1-rem">Join Us</span>
    </div>
}
