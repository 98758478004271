import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {ListItemIcon, ListItemText} from "@mui/material";
import {Delete, Edit, Send} from "@mui/icons-material";
import {Guest} from "../models/Guest";
import {Group} from "../models/Group";


interface MoreMenuProps {
    group: Group,
    guest: Guest,
    sendInviteHandler: (group: Group) => void,
    editGuestHandler: (guest: Guest) => void,
    editGroupHandler: (group: Group) => void,
    deleteGuestHandler: (guest: Guest) => void
}

export default function MoreMenu({
                                     group,
                                     guest,
                                     sendInviteHandler,
                                     editGuestHandler,
                                     editGroupHandler,
                                     deleteGuestHandler
                                 }: MoreMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSendInvite = () => {
        sendInviteHandler(group);
        handleClose();
    };
    const handleEditGuest = () => {
        editGuestHandler(guest);
        handleClose();
    };
    const handleEditGroup = () => {
        editGroupHandler(group);
        handleClose();
    };
    const handleDeleteGuest = () => {
        deleteGuestHandler(guest);
        handleClose();
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="more-button"
                aria-controls={open ? 'more-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleMenuClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="more-menu"
                MenuListProps={{
                    'aria-labelledby': 'more-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem key="sendInvite" disabled={group.inviteSent} onClick={handleSendInvite}>
                    <ListItemIcon>
                        <Send fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>{group.inviteSent ? 'Invite Sent' : 'Send Invite'}</ListItemText>
                </MenuItem>
                <MenuItem key="editGuest" onClick={handleEditGuest}>
                    <ListItemIcon>
                        <Edit fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Edit Guest</ListItemText>
                </MenuItem>
                <MenuItem key="editGroup" onClick={handleEditGroup}>
                    <ListItemIcon>
                        <Edit fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Edit Group & Email</ListItemText>
                </MenuItem>
                <MenuItem key="delete" onClick={handleDeleteGuest}>
                    <ListItemIcon>
                        <Delete fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Remove Guest</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
}