import React from 'react';
import parkingMap from "../images/ParkingMap.jpg";

export default function Faqs() {

    return <div className="faq">
        <a id="faq-anchor" className="anchor" href="/#"> </a>
        <div className="faq-content">
            <span className="font-large-cursive-label margin-bottom-2-rem">Frequently Asked Questions</span>

            <div className="faq-q">Q: Where can I RSVP?</div>
            <div className="faq-a">You can RSVP with the link included in your email invitation.</div>
            <div className="faq-q">Q: When is the RSVP deadline?</div>
            <div className="faq-a">Please RSVP no later than Sept 30th, 2022.</div>
            <div className="faq-q">Q: Can I bring a plus one?</div>
            <div className="faq-a">
                Unfortunately, we have a strict guest list and we will only be able to accommodate those listed on the
                invitation.
            </div>
            <div className="faq-q">Q: What’s the dress code?</div>
            <div className="faq-a">The dress code for our wedding is semi-formal, or cocktail attire.</div>
            <div className="faq-q">Q: Will the ceremony and reception be indoors or outdoors?</div>
            <div className="faq-a">
                Both the ceremony and reception will be indoors. If the weather permits, outdoor patios will be available.
            </div>
            <div className="faq-q">Q: Is there a gift registry? Should I bring a gift?</div>
            <div className="faq-a">
                Your presence at our wedding is the greatest gift of all. However, if you wish to honour us with a gift,
                a cash gift would be very welcome.
            </div>
            <div className="faq-q">Q: How should I get there?</div>
            <div className="faq-a">
                The Broadview Hotel can be reached by TTC streetcar routes 501(Queen) and 504B(King), Uber/Lyft/Taxi,
                or by car. If you plan on consuming alcohol during the event, we encourage planning your visit with
                responsible drinking in mind.
            </div>
            <div className="faq-q">Q: Where should I park?</div>
            <div className="faq-a">
                There are two Green P parking spaces available for you to park, as well as street parking. The Green P
                spaces allow for overnight parking.
            </div>
            <img src={parkingMap} alt="Map showing green P parking lots and street parking."/>
        </div>
    </div>
}
