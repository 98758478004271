import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom'
import {Guest} from '../models/Guest';
import GuestInfo from './GuestInfo';
import Button from '@mui/material/Button';
import detailsPhoto from '../images/eshoot_ellen_dave-33.jpg'

export default function Rsvp() {
    const navigate = useNavigate();
    const {code} = useParams();
    const [guests, setGuests] = useState<Guest[]>();
    const [showLoadError, setShowLoadError] = useState(false);
    const [alreadyRsvpd, setAlreadyRsvpd] = useState(false);
    const [showRsvpError, setShowRsvpError] = useState(false);
    const [showValidationError, setShowValidationError] = useState(false);
    const [showPassedDeadlineError, setShowPassedDeadlineError] = useState(false);

    useEffect(() => {
        if (code) {
            fetch(`https://ellendavewedding.herokuapp.com/rsvp?code=${code}`, {method: 'GET'})
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status === 403) {
                        setAlreadyRsvpd(true);
                    } else if (response.status === 404) {
                        setShowLoadError(true);
                    } else if (response.status === 423) {
                        setShowPassedDeadlineError(true);
                    } else if (response.status === 500) {
                        setShowLoadError(true);
                    }
                    return [];
                })
                .then(data => {
                    setGuests(data);
                })
                .catch(error => {
                    console.error(error);
                    setShowLoadError(true);
                });
        } else {
            setShowLoadError(true);
        }
    }, [code]);

    const updateGuest = (updatedGuest: Guest) => {
        setGuests(guests?.map(guest => {
            if (guest.name === updatedGuest.name) {
                return updatedGuest;
            }
            return guest;
        }));
    };

    const handleRsvp = () => {
        setShowValidationError(false);
        setShowRsvpError(false);
        setShowPassedDeadlineError(false);

        const options: RequestInit = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(guests),
        };
        fetch(`https://ellendavewedding.herokuapp.com/rsvp?code=${code}`, options)
            .then(response => {
                if (response.ok) {
                    navigate("/success");
                } else if (response.status === 400) {
                    setShowValidationError(true);
                } else if (response.status === 423) {
                    setShowPassedDeadlineError(true);
                    setGuests([]);
                } else {
                    setShowRsvpError(true);
                }
            })
            .catch(error => {
                console.log(error);
                setShowRsvpError(true);
            })
    };

    return <div className="rsvp">
        <div className="rsvp-container">
            <div className="rsvp-image">
                <img className="details-image" src={detailsPhoto} alt="Ellen and Dave"/>
                <div className="rsvp-image-filter">
                    <div className="rsvp-spacer"/>
                    <span className="font-giant-label font-white margin-bottom-2-rem">RSVP</span>
                    <span className="font-medium-label font-white">We look forward to seeing you!</span>
                    <span className="font-medium-label font-white">Please fill out the form below.</span>
                </div>
            </div>
            <div className="rsvp-text">
                {!showLoadError && !alreadyRsvpd && !showPassedDeadlineError && !guests &&
                <div className="font-regular-label margin-bottom-4-rem">
                    Loading guest details...
                </div>}

                {showLoadError && <div className="font-regular-label margin-bottom-4-rem">
                    Please use the link in the email invitation to RSVP.
                </div>}

                {alreadyRsvpd && <div className="font-regular-label margin-bottom-4-rem">
                    You have already RSVP’d to our wedding! If you would like to make any changes, please contact us via
                    email.
                </div>}

                {showPassedDeadlineError && <div className="font-regular-label margin-bottom-4-rem">
                    RSVP deadline has passed! If you would like to RSVP or make any changes, please contact us via
                    email.
                </div>}

                {guests && guests.map(guest =>
                    <GuestInfo guest={guest} updateGuest={updateGuest}/>
                )}

                {!showLoadError && !alreadyRsvpd && !showPassedDeadlineError && guests &&
                <div className="flex-row flex-justify-center margin-top-2-rem">
                    <Button variant="contained" onClick={handleRsvp}>Submit</Button>
                </div>}

                {showValidationError && <div className="font-regular-bold-label margin-top-2-rem margin-bottom-4-rem">
                    Please complete all fields then try again. If the issue persists, please send us your RSVP via
                    email.
                </div>}

                {showRsvpError && <div className="font-regular-bold-label margin-top-2-rem margin-bottom-4-rem">
                    Something went wrong while submitting your RSVP. Please send us your RSVP via email.
                </div>}
            </div>
        </div>

        <div className="header-bar"/>
        <span className="e-and-d font-regular-cursive-label">E & D</span>
    </div>
}
