import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Guest} from "../models/Guest";

interface DeleteDialogProps {
    guest?: Guest,
    deleteHandler: (guest: Guest) => void,
    cancelHandler: () => void
}

export default function DeleteDialog({
                                         guest,
                                         deleteHandler,
                                         cancelHandler
                                     }: DeleteDialogProps) {
    const [confirmText, setConfirmText] = useState("");

    if (!guest) {
        return null;
    }

    const deleteButtonClick = () => {
        if (confirmText === guest.name) {
            setConfirmText("");
            deleteHandler(guest);
        }
    };

    const cancelButtonClick = () => {
        setConfirmText("");
        cancelHandler();
    };

    return (
        <Dialog open={Boolean(guest)} onClose={cancelButtonClick} scroll="body">
            <DialogTitle>Remove {guest.name}?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to remove {guest.name} from the guest list?
                    To remove this guest, type in <mark>{guest.name}</mark>:
                </DialogContentText>
                <TextField
                    autoFocus={true}
                    margin="dense"
                    fullWidth
                    variant="standard"
                    onChange={event => setConfirmText(event.target.value)}
                    value={confirmText}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelButtonClick}>Cancel</Button>
                <Button onClick={deleteButtonClick}>Remove</Button>
            </DialogActions>
        </Dialog>
    );
}
