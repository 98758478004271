import React from 'react';
import './App.scss';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import SaveTheDate from "./SaveTheDate/SaveTheDate";
import Landing from "./Landing/Landing";
import Rsvp from "./Rsvp/Rsvp";
import Success from "./Rsvp/Success";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Admin from "./Admin/Admin";

const theme = createTheme({
    palette: {
        primary: {
            main: '#233653',
        }
    },
    typography: {
        fontFamily: 'Montserrat'
    }
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Landing/>}/>
                        <Route path="/savethedate" element={<SaveTheDate/>}/>
                        <Route path="/rsvp/:code" element={<Rsvp/>}/>
                        <Route path="/success" element={<Success/>}/>
                        <Route path="/admin/:token" element={<Admin/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
        </ThemeProvider>
    );
}

export default App;
