import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Add from "@mui/icons-material/Add";
import {NewGuestGroup} from "../models/NewGuestGroup";

interface AddGuestsDialogProps {
    open: boolean,
    addGuestsHandler: (newGuestGroup: NewGuestGroup) => void,
    closeHandler: () => void
}

export default function AddGuestsDialog({
                                            open,
                                            addGuestsHandler,
                                            closeHandler
                                        }: AddGuestsDialogProps) {
    const [groupName, setGroupName] = useState("");
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [names, setNames] = useState<string[]>([]);
    const [emails, setEmails] = useState<string[]>([]);
    const [errorState, setErrorState] = useState("none");

    const addGuestRow = () => {
        if (name) {
            setNames([...names, name]);
            setName("");
            setErrorState('none');
        }
    };

    const addEmailRow = () => {
        if (email) {
            setEmails([...emails, email]);
            setEmail("");
            setErrorState('none');
        }
    };

    const addButtonClick = () => {
        if (!groupName) {
            setErrorState('need group name');
            return;
        }
        if (names.length === 0) {
            setErrorState('need guest');
            return;
        }
        if (emails.length === 0) {
            setErrorState('need email');
            return;
        }

        setErrorState('none');

        addGuestsHandler({
            groupName,
            names,
            emails
        });

        cleanAndCloseDialog();
    };

    const cleanAndCloseDialog = () => {
        setErrorState('none');
        setGroupName("");
        setName("");
        setEmail("");
        setNames([]);
        setEmails([]);
        closeHandler();
    };

    return (
        <Dialog open={open} onClose={cleanAndCloseDialog} maxWidth="sm" scroll="body" fullWidth disableAutoFocus>
            <DialogTitle>Add Guests</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus={true}
                    margin="dense"
                    type="groupName"
                    label="Group Name"
                    fullWidth
                    variant="outlined"
                    onChange={event => setGroupName(event.target.value)}
                    value={groupName}
                />
                {errorState === 'need group name' && <DialogContentText style={{color: 'red'}}>
                    * Group name is required
                </DialogContentText>}

                <DialogContentText style={{marginTop: '2rem'}}>Added Guests: {names.length}</DialogContentText>
                {names.map(name => (<div className="font-regular-label" key={name}>{name}</div>))}

                <div className="flex-row flex-justify-space-between">
                    <TextField
                        style={{marginTop: '2rem', marginRight: '1rem'}}
                        margin="dense"
                        label="New Guest Name"
                        fullWidth
                        variant="outlined"
                        onChange={event => setName(event.target.value)}
                        value={name}
                    />
                    <Button style={{height: '2rem', marginTop: '3.5rem'}}
                            variant="outlined"
                            startIcon={<Add/>}
                            onClick={addGuestRow}>
                        Add
                    </Button>
                </div>

                {errorState === 'need guest' && <DialogContentText style={{color: 'red'}}>
                    * At least one guest is required
                </DialogContentText>}

                <DialogContentText style={{marginTop: '2rem'}}>Added Guest Emails: {emails.length}</DialogContentText>
                {emails.map(email => (<div className="font-regular-label" key={email}>{email}</div>))}

                <div className="flex-row flex-justify-space-between">
                    <TextField
                        style={{marginTop: '2rem', marginRight: '1rem'}}
                        margin="dense"
                        label="New Guest Email"
                        fullWidth
                        variant="outlined"
                        onChange={event => setEmail(event.target.value)}
                        value={email}
                    />
                    <Button style={{height: '2rem', marginTop: '3.5rem'}}
                            variant="outlined"
                            startIcon={<Add/>}
                            onClick={addEmailRow}>
                        Add
                    </Button>
                </div>

                {errorState === 'need email' && <DialogContentText style={{color: 'red'}}>
                    * At least one email is required
                </DialogContentText>}

            </DialogContent>
            <DialogActions>
                <Button onClick={cleanAndCloseDialog}>Cancel</Button>
                <Button onClick={addButtonClick}>Add Group</Button>
            </DialogActions>
        </Dialog>
    );
}
