import React from 'react';
import detailsPhoto from "../images/eshoot_ellen_dave-70.jpg";

export default function Details() {

    return <div className="details">
        <a id="details-anchor" className="anchor" href="/#"> </a>
        <div className="details-container">
            <div className="details-text">
                <span className="font-large-cursive-label margin-bottom-1-rem">We’re getting married!</span>
                <span className="font-regular-label margin-bottom-2-rem">
                    Celebrate with us as we say our vows and join in marriage in the presence of family and friends.
                </span>
                <span className="font-small-label margin-top-2-rem">When</span>
                <span className="font-large-label">Friday, Nov 4th, 2022</span>
                <span className="font-small-label margin-top-2-rem">Where</span>
                <span className="font-large-label">The Broadview Hotel</span>
            </div>
            <img className="details-image" src={detailsPhoto} alt="Ellen and Dave"/>
        </div>
    </div>
}
