import React from 'react';
import theBroadviewHotel from "../images/thebroadview.jpeg";

export default function Schedule() {

    return <div className="schedule">
        <a id="schedule-anchor" className="anchor" href="/#"> </a>
        <div className="schedule-container">
            <img className="schedule-image" src={theBroadviewHotel} alt="The Broadview Hotel"/>
            <div className="schedule-text">
                <span className="font-large-cursive-label margin-bottom-2-rem">The Broadview Hotel</span>
                <span className="font-huge-label margin-top-1-rem">4:00 pm</span>
                <span className="font-regular-label margin-bottom-2-rem">Ceremony</span>
                <span className="font-huge-label">5:00 pm</span>
                <span className="font-regular-label margin-bottom-2-rem">Cocktail Hour</span>
                <span className="font-huge-label">6:00 pm</span>
                <span className="font-regular-label margin-bottom-2-rem">Reception</span>
                <a className="flex-column"
                   href="https://g.page/the-broadview-hotel-toronto?share"
                   target="_blank"
                   rel="noreferrer">
                    <span className="font-regular-label margin-top-2-rem">106 Broadview Avenue</span>
                    <span className="font-regular-label">Toronto, Ontario</span>
                </a>
            </div>
        </div>
    </div>
}
