import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Group} from "../models/Group";
import MoreMenu from "./MoreMenu";
import {Guest} from "../models/Guest";


interface GuestsTableProps {
    groups: Group[],
    sendInviteHandler: (group: Group) => void,
    editGuestHandler: (guest: Guest) => void,
    editGroupHandler: (group: Group) => void,
    deleteGuestHandler: (guest: Guest) => void
}

export default function GuestsTable({
                                        groups,
                                        sendInviteHandler,
                                        editGroupHandler,
                                        editGuestHandler,
                                        deleteGuestHandler
                                    }: GuestsTableProps) {
    return (
        <TableContainer className="guests-table" component={Paper}>
            <Table sx={{minWidth: 650}} size="small" aria-label="guests table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Group</TableCell>
                        <TableCell align="left">Guest</TableCell>
                        <TableCell align="left">Invite</TableCell>
                        <TableCell align="left">Attending</TableCell>
                        <TableCell align="left">Dinner</TableCell>
                        <TableCell align="left">Dietary Restrictions</TableCell>
                        <TableCell align="left"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groups.map(group =>
                        group.guests.map(guest => (
                            <TableRow
                                key={guest.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell align="left">{group.names}</TableCell>
                                <TableCell align="left">{guest.name}</TableCell>
                                <TableCell align="left">{group.inviteSent ? "Sent" : "Not Sent"}</TableCell>
                                <TableCell
                                    align="left">{guest.attending === null ? "No response" : guest.attending ? "Yes" : "No"}</TableCell>
                                <TableCell align="left">{guest.foodDescription}</TableCell>
                                <TableCell align="left">{guest.dietary}</TableCell>
                                <TableCell align="left">
                                    <MoreMenu
                                        group={group}
                                        guest={guest}
                                        sendInviteHandler={sendInviteHandler}
                                        editGroupHandler={editGroupHandler}
                                        editGuestHandler={editGuestHandler}
                                        deleteGuestHandler={deleteGuestHandler}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
