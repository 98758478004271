import * as React from 'react';
import {Music} from "../models/Music";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Button, Card, CardContent, DialogActions, Grid} from "@mui/material";

interface MusicDialogProps {
    musicReqs?: Music[],
    closeHandler: () => void
}

export default function MusicDialog({musicReqs, closeHandler}: MusicDialogProps) {

    const getColor = () => {
        return "hsl(" + 360 * Math.random() + ',' +
            (25 + 70 * Math.random()) + '%,' +
            (85 + 10 * Math.random()) + '%)'
    };

    if (!musicReqs) {
        return null;
    }

    return (
        <Dialog open={Boolean(musicReqs)} onClose={closeHandler} maxWidth="md" scroll="body" fullWidth disableAutoFocus>
            <DialogTitle>Music Requests</DialogTitle>

            <DialogContent className='font-regular-label'>
                <Grid container spacing={2} justifyContent="center">
                    {musicReqs
                        .filter(music => music.music)
                        .map(music =>
                        <Card sx={{maxWidth: 'sm', marginTop: '1rem', marginLeft: '1rem', paddingBottom: '0', backgroundColor: getColor()}}>
                            <CardContent sx={{maxWidth: 'sm', marginTop: '1rem', marginLeft: '1rem', paddingBottom: '0'}}>
                                <Grid container spacing={2} direction="column" alignItems="stretch">
                                    <div>“{music.music}”</div>
                                    <div style={{marginTop: '1rem', textAlign: 'end', color: 'gray'}}>{music.name}</div>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={closeHandler}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};