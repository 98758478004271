import React from 'react';

export default function Success() {
    return <div className="success">
        <div className="success-spacer"/>
        <span className="font-large-label font-white font-shadow">You have now RSVP'd to our wedding.</span>
        <span className="font-large-cursive-label font-white margin-bottom-1-rem">Complete!</span>
    </div>
}

