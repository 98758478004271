import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Guest} from "../models/Guest";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";

interface EditGuestDialogProps {
    guest?: Guest,
    updateGuestHandler: (group: Guest) => void,
    closeHandler: () => void
}

export default function EditGuestDialog({
                                            guest,
                                            updateGuestHandler,
                                            closeHandler
                                        }: EditGuestDialogProps) {
    const [editGuest, updateEditGuest] = useState(guest);
    const [attending, updateAttending] = useState<string>();
    const [dinner, updateDinner] = useState<string>();
    const [dietary, updateDietary] = useState<string>();
    const [errorState, setErrorState] = useState("none");

    useEffect(() => {
        if (guest) {
            updateEditGuest(JSON.parse(JSON.stringify(guest)));
            updateAttending(guest.attending === null ? 'null' : String(guest.attending));
            updateDinner(guest.food === null ? 'null' : guest.food);
            updateDietary(guest.dietary);
        }
    }, [guest]);

    if (!editGuest) {
        return null;
    }

    const getFoodDescription = (dinner: string | undefined) => {
        switch (dinner) {
            case 'beef':
                return 'Braised Beef Short Rib';
            case 'duck':
                return 'Duck Breast';
            case 'pasta':
                return 'Hand-made Orecchiette (Vegetarian)';
        }
        return undefined;
    };

    const updateGuestButtonClick = () => {
        if (!editGuest?.name) {
            setErrorState('need guest name');
            return;
        }

        setErrorState('none');

        if (attending === 'true') {
            updateGuestHandler({
                ...editGuest,
                attending: true,
                food: dinner === 'null' ? undefined : dinner,
                foodDescription: getFoodDescription(dinner),
                dietary: dietary ? dietary : undefined
            });
        } else {
            updateGuestHandler({
                ...editGuest,
                attending: attending === 'null' ? undefined : false,
                food: undefined,
                foodDescription: undefined,
                dietary: undefined,
            });
        }

        cleanAndCloseDialog();
    };

    const cleanAndCloseDialog = () => {
        setErrorState('none');
        updateEditGuest(undefined);
        closeHandler();
    };

    return (
        <Dialog open={Boolean(editGuest)}
                onClose={cleanAndCloseDialog}
                maxWidth="sm"
                scroll="body"
                fullWidth
                disableAutoFocus
        >
            <DialogTitle>Edit Guest {editGuest?.name}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus={true}
                    margin="dense"
                    label="Guest Name"
                    fullWidth
                    variant="outlined"
                    onChange={event => editGuest && updateEditGuest({...editGuest, name: event.target.value})}
                    value={editGuest.name}
                />
                {errorState === 'need guest name' && <DialogContentText style={{color: 'red'}}>
                    * Guest name is required
                </DialogContentText>}

                <DialogContentText style={{marginTop: '1rem'}}>Attending?</DialogContentText>
                <RadioGroup
                    aria-labelledby="attending"
                    defaultValue={attending}
                    name="attending-radio-group"
                    onChange={event => updateAttending(event.target.value)}
                >
                    <FormControlLabel value="true" control={<Radio />} label="Attending" />
                    <FormControlLabel value="false" control={<Radio />} label="Not Attending" />
                    <FormControlLabel value="null" control={<Radio />} label="No Response" />
                </RadioGroup>

                <DialogContentText style={{marginTop: '1rem'}}>Dinner?</DialogContentText>
                <RadioGroup
                    aria-labelledby="dinner"
                    defaultValue={dinner}
                    name="dinner-radio-group"
                    onChange={event => updateDinner(event.target.value)}
                >
                    <FormControlLabel value="beef" control={<Radio />} label="Beef" disabled={attending !== 'true'}/>
                    <FormControlLabel value="duck" control={<Radio />} label="Duck" disabled={attending !== 'true'}/>
                    <FormControlLabel value="pasta" control={<Radio />} label="Pasta" disabled={attending !== 'true'}/>
                    <FormControlLabel value="null" control={<Radio />} label="No Selection" disabled={attending !== 'true'}/>
                </RadioGroup>

                <TextField
                    autoFocus={true}
                    margin="dense"
                    label="Dietary Restrictions:"
                    fullWidth
                    variant="outlined"
                    onChange={event => updateDietary(event.target.value)}
                    value={dietary}
                    style={{marginTop: '2rem'}}
                    disabled={attending !== 'true'}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={cleanAndCloseDialog}>Cancel</Button>
                <Button onClick={updateGuestButtonClick}>Update Guest</Button>
            </DialogActions>
        </Dialog>
    );
}
